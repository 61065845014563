<template>
    <div class="section quiz_section">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-5">
                    <div v-html="description" class="card" style="padding: 20px;"></div>
                </div>
            </div>

            <quiz :questions="questions" :duration="duration" :isDisplayAll="display_all" :sectionName="name"
                  @submitForm="submitForm"/>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "QuizSection",
        components: {
            quiz: () => import('@/components/Quiz'),
        },
        data() {
            return {
                id: null,
                description: null,
                display_all: true,
                duration: 0,
                name: null,
                questions: null,
            }
        },
        computed: {
            ...mapState(['loginData']),
        },
        created() {
            this.init()
        },
        watch: {
            '$route.params.sectionId': function (id) {
                console.log(id);
                this.init()
            }
        },
        methods: {
            init() {
                if (localStorage.getItem('current-section') === null) {
                    localStorage.removeItem('user');
                    this.$router.push({name: 'finish-quiz'});
                } else {
                    const {description, display_all, duration, id, name, questions} = JSON.parse(localStorage.getItem('current-section'));
                    console.warn('duration', duration);
                    this.id = id;
                    this.name = name;
                    if (name.toLowerCase().indexOf('rmib') != -1) {
                      let gender = localStorage.getItem('gender');
                      this.questions = questions.filter(function(q) { return q.gender == gender });
                    } else {
                      this.questions = questions;
                    }
                    this.duration = duration;
                    this.description = description;
                    this.display_all = display_all;
                }
            },
            submitForm(form) {
                const generateForm = form.map(answer => ({
                    ...answer,
                    username: this.loginData.username,
                }))
                this.$axios.post(`sections/${this.id}/store-answer`, generateForm).then((res) => {
                    console.log(res);
                    localStorage.removeItem('answers-temp');
                    const {sections} = JSON.parse(localStorage.getItem('test-data'));
                    const findIndex = sections.findIndex((section) => section.id === this.id);
                    if (sections.length - 1 > findIndex) {
                        const newSection = sections[findIndex + 1];
                        localStorage.setItem('current-section', JSON.stringify(newSection));
                        this.$router.push({name: 'section', params: {sectionId: newSection.id}});
                    } else {
                        localStorage.removeItem('answers-temp');
                        localStorage.removeItem('test-data');
                        localStorage.removeItem('current-section');
                        localStorage.removeItem('user');
                        localStorage.removeItem('gender');
                        this.$router.push({name: 'finish-quiz'});
                    }
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    }
</script>

<style scoped>
</style>
